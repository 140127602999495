import React, { useState, useEffect, Component } from 'react';
import logo from './logo.svg';
import './App.css';
import protobuf from "protobufjs";
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  let pb = protobuf.parse(`
  syntax = "proto3";
  package ih;
  
  message Splunk {
      int64 ts = 1;
      sint32 diff = 2;
      int32 id = 3;
      int32 num = 4;
  }
  
  message Batch {
      repeated Splunk splunks = 1;
  }
  
  message SingalMessage {
      string id = 1;
      int64 ts = 2;
      string type = 3;
      string to = 4;
      string from = 5;
      string message = 6;
  }
  
  message SingalMessageBatch {
      repeated SingalMessage messages = 1;
  }
  `);
  const { loginWithRedirect, user, isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();

  const [to, setTo] = React.useState();
  const [message, setMessage] = React.useState();
  const [userMetadata, setUserMetadata] = useState(null);
  const [helloes, setHelloes] = React.useState([]);
  const [newHelloes, setNewHelloes] = React.useState([]);
  const [splunks, setSplunks] = React.useState({});
  const [newSplunks, setNewSplunks] = React.useState([]);

  useEffect(() => {
    var Batch = pb.root.lookupType("ih.Batch");

    let ws = new WebSocket('wss://api.insta.carera.se/ws');
    ws.onopen = () => {
      console.log('connected')
    };

    ws.onmessage = async evt => {
      let buffer = await evt.data.arrayBuffer();
      var content = new Uint8Array(buffer, 0, buffer.length);
      let batch = Batch.decode(content);

      console.log(batch);

      setNewSplunks(batch.splunks);
    }
    ws.onclose = () => {
      console.log('disconnected')
    }
  }
    , []);

  useEffect(async () => {
    var Batch = pb.root.lookupType("ih.SingalMessageBatch");
    let at = await getAccessTokenSilently({
      audience: `https://api.insta.carera.se`,
    });
    let ws = new WebSocket(`wss://api.insta.carera.se/signal-receiver`);
    ws.onopen = () => {
      console.log('connected')
      ws.send(at)
    };

    ws.onmessage = async evt => {
      let buffer = await evt.data.arrayBuffer();
      var content = new Uint8Array(buffer, 0, buffer.length);
      let batch = Batch.decode(content);

      console.log(batch);

      setNewHelloes(batch.messages);
    }
    ws.onclose = () => {
      console.log('disconnected')
    }
  }
    , []);

  useEffect(() => {
    let m = { ...splunks };
    for (let o of newSplunks) {
      m[o.id] = o;
    }
    setSplunks(m);
  },
    [newSplunks])
  useEffect(() => {

    setHelloes(helloes.concat(newHelloes));
  },
    [newHelloes])


  let new_message = async () => {
    const type = "offer";
    let at = await getAccessTokenSilently({
      audience: `https://api.insta.carera.se`,
    });
    await fetch(`//api.insta.carera.se/signal/${to}/${type}`, {
      method: 'POST',
      body: message,
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let post = async () => {
    let at = await getAccessTokenSilently({
      audience: `https://api.insta.carera.se`,
    });
    await fetch("https://api.insta.carera.se/splunks", {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let inc = async id => {
    let at = await getAccessTokenSilently({
      audience: `https://api.insta.carera.se`,
    });
    await fetch(`https://api.insta.carera.se/splunks/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };
  let del = async id => {
    let at = await getAccessTokenSilently({
      audience: `https://api.insta.carera.se`,
    });
    await fetch(`https://api.insta.carera.se/splunks/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${at}`
      }
    });
  };



  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "YOUR_DOMAIN";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://api.insta.carera.se`,
        });
        console.log(accessToken);

      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, []);

  return (
    <div className="App">

      {isAuthenticated ? (
        <div>
          <div>
            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <button onClick={() => logout({ returnTo: window.location.origin })}>
              Log Out
          </button>
          </div>

        </div>
      ) : <button onClick={() => loginWithRedirect()}>Log In</button>}

      {isAuthenticated &&
        <div>
          <ul>
            {Object.values(splunks).filter(s => s.diff > 0).map(s => <li key={s.id}>{s.id}:{s.num} <button onClick={() => inc(s.id)}>inc</button><button onClick={() => del(s.id)}>del</button> </li>)}
          </ul>
          <button onClick={post}>new</button>
          <ul>
            {helloes.map((o, i) => <li key={i}>{o.from}-{o.to} : {o.message} </li>)}
          </ul>
          <input type="text" value={to} onChange={event => setTo(event.target.value)} />
          <br />
          <input type="text" value={message} onChange={event => setMessage(event.target.value)} />
          <button onClick={new_message}>new</button>
        </div>
      }
    </div>
  );
}

export default App;
